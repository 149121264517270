import * as React from "react";

import { usePaymentContext } from "@bokio/contexts/PaymentContext/PaymentContext";
import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import { useFeatureAvailability } from "@bokio/hooks/useFeatureAvailability/useFeatureAvailability";
import { AnalyticsEventCategory, useMetric } from "@bokio/hooks/useMetric/useMetric";
import { useUserPreference } from "@bokio/hooks/useUserPreference/useUserPreference";
import { BankLangFactory, GeneralLangFactory, SettingsLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useActiveBankConnection } from "@bokio/mobile-web-shared/hooks/useActiveBankConnection/useActiveBankConnection";
import { getRoute } from "@bokio/shared/route";
import Feature from "@bokio/shared/services/feature/Feature";
import { useCompanyUser } from "@bokio/shared/state/requests";
import { getCurrentCountryIso, swedishCountryIso2 } from "@bokio/shared/utils/culture";
import { hasFailedPaymentState } from "@bokio/utils/priceplanUtils";

import { filterMenuSections, MenuBuilder } from "../../utils/MenuHelper";
import { ToDoCounterType } from "../MenuCounter/MenuCounter";

import type { MenuSection } from "../../utils/MenuHelper";

import CompanyType = m.Entities.CompanyType;

type SideMenuVisitedStates = m.Bokio.Common.Contract.Services.UserPreferences.SideMenuVisitedStates;
type MenuItemKey = keyof SideMenuVisitedStates;

export interface CompanyMenuProviderInnerProps extends CompanyMenuProviderProps {
	company: m.Core.CompanyInfo;
	companyUserPermissions: m.Settings.Controllers.UserAccess | undefined;
}

const CompanyMenuProviderInner = (props: CompanyMenuProviderInnerProps) => {
	const { company, companyUserPermissions } = props;
	const features = useFeatureAvailability();
	const deviceQuery = useDeviceQuery();
	const [addMetric] = useMetric();
	const {
		anyConnectionSupportsPayment,
		anyConnectionSupportsCard,
		hasActiveBusinessAccountWithExternalSystem,
		anyConnectionSupportsBankInbox,
	} = useActiveBankConnection();
	const { currentUserPermission } = usePaymentContext();
	const hasSvea = hasActiveBusinessAccountWithExternalSystem(m.Entities.Bank.ExternalSystem.Svea);

	const { preferenceValue, setPreference } = useUserPreference(
		company.Id,
		m.Entities.UserPreferenceType.SideMenuVisited,
	);

	const menuVisitedStates = preferenceValue
		? preferenceValue.SideMenuVisited
			? preferenceValue.SideMenuVisited.Value
			: {
					AccountingServices: false,
					BusinessServices: false,
					BankFeed: false,
					Integrations: false,
					PaymentLinks: false,
					Quotes: false,
					VacationTracking: false,
				}
		: undefined;

	const visitMenuItem = (key: MenuItemKey) => {
		if (menuVisitedStates && menuVisitedStates[`${key}`] === false) {
			menuVisitedStates[`${key}`] = true;
			setPreference({
				isCompanySpecific: false,
				value: { SideMenuVisited: { Value: menuVisitedStates } },
			});
		}
	};

	const lang = GeneralLangFactory();
	const settingsLang = SettingsLangFactory();
	const bankLang = BankLangFactory();
	const menu = new MenuBuilder<m.Settings.Controllers.UserAccess>("");
	const companyIdParams = { company: company.Id };

	const hasFailedPayments = hasFailedPaymentState(company.BillingPaymentState);

	// prettier-ignore
	menu.addSection("overview", lang.Overview)
		.requirePaidPlan()
		.route(getRoute("dashboard", companyIdParams))
		.requireAccess("ViewBookkeeping");
	// prettier-ignore
	menu.addSection("todo", lang.ThingsToDo_label)
		.requirePaidPlan()
		.addCounter(ToDoCounterType.All)
		.addSub(getRoute("todo", companyIdParams), lang.Todo)
		.requireAccess("ViewBookkeeping", "Bookkeep")
		.addCounter(ToDoCounterType.ToDoNow)
		.addSub(getRoute("importantDates", companyIdParams), lang.ToDo_ImportantDates_heading)
		.requireAccess("ImportantDates")
		.requireFeatureAvailability("ImportantDates")
		.addCounter(ToDoCounterType.ImportantDates)
		.addSub(getRoute("preliminaryTaxes", companyIdParams), lang.Todo_PrelTax_Title)
		.requireAccess("ImportantDates")	//TODO specific access?
		.requireFeatureAvailability("ImportantDates")	//TODO specific feature availability?
		.requireFeatureToggleEnabled(m.Core.Features.UnderDevelopment);

	// prettier-ignore
	menu.addSection("folder", lang.Uploads)
		.requirePaidPlan()
		.route(getRoute("uploads", companyIdParams))
		.requireAccess("UploadReceipt");

	// prettier-ignore
	menu.addSection("reports", lang.Reports)
		.requirePaidPlan()
		.addSub(getRoute("drillDownReport", companyIdParams), lang.DDR_PageTitle)
		.requireAccess("Reports")
		.requireAccess("ViewBookkeeping")
		.requireFeatureAvailability("Drilldown")
		.addSub(getRoute("resultReport", companyIdParams), lang.Menu_ResultReport)
		.requireAccess("Reports")
		.requireAccess("ViewBookkeeping")
		.addSub(getRoute("ledgerReport", companyIdParams), lang.Menu_Ledger)
		.requireAccess("Reports")
		.requireAccess("ViewBookkeeping")
		.addSub(getRoute("balanceReport", companyIdParams), lang.Menu_BalanceReport)
		.requireAccess("Reports")
		.requireAccess("ViewBookkeeping")
		.addSub(getRoute("vatList", companyIdParams), lang.Menu_VatReport)
		.requireAccess("Reports")
		.requireAccess("ViewBookkeeping")
		.addSub(getRoute("invoicesReport", companyIdParams), lang.Menu_InvoicesReport)
		.requireAccess("Reports")
		.requireAccess("Invoices")
		.addSub(getRoute("agedDebtorsReport", companyIdParams), lang.Menu_AccountsReceivable)
		.requireAccess("Reports")
		.requireAccess("Invoices")
		.addSub(getRoute("agedCreditorsReport", companyIdParams), lang.Menu_AgedCreditors)
		.requireAccess("Reports")
		.requireAccess("SupplierInvoices")
		.addSub(getRoute("salaryReports", companyIdParams), lang.Menu_SalaryReports)
		.requireAccess("Reports")
		.requireAccess("Salaries")
		.requireFeatureAvailability("Salary")
		.addSub(getRoute("tagReport", companyIdParams), lang.Menu_TagReport)
		.requireAccess("Reports")
		.requireAccess("ViewBookkeeping");

	menu.addDivider();

	// prettier-ignore
	menu.addSection("bank", lang.Menu_Bank)
		.requirePaidPlan()
		.route(getRoute("bankList", companyIdParams))
		.requireAccess("UploadBank")
		.addOnClick(() => {
			visitMenuItem("BankFeed")
		})
		.addSub(getRoute("bankList", companyIdParams), lang.Menu_BankAndTaxAccounts)
		.addOnClick(() => {
			visitMenuItem("BankFeed")
		})
		.requireAccess("UploadBank")
		.addSub(getRoute("bankPayments", companyIdParams), lang.Menu_Payments)
		.requireAccess("UploadBank")
		.addOnClick(() => {
			addMetric(AnalyticsEventCategory.Bba, "GoToPayments", "SideMenu")
		})
		.require(() => anyConnectionSupportsPayment)
		.addSub(getRoute("bankTransactions", companyIdParams), lang.Menu_Transactions)
		.requireAccess("Bookkeep")
		.addSub(getRoute("cardList", companyIdParams), bankLang.BokioCards)
		.requireAccess("UploadBank")
		.addOnClick(() => {
			addMetric(AnalyticsEventCategory.Bba, "GoToCards", "SideMenu")
		})
		.require(() => anyConnectionSupportsCard && currentUserPermission.CanSignPayment)
		.addSub(getRoute("bankInbox", companyIdParams), bankLang.BankInbox_Title)
		.requireAccess("UploadBank")
		.addOnClick(() => {
			addMetric(AnalyticsEventCategory.Bba, "GoToBankInbox", "SideMenu")
		})
		.require(() => anyConnectionSupportsBankInbox && currentUserPermission.CanSignPayment)
		.addSub(getRoute("sveaInternationalPayments", companyIdParams), bankLang.InternationalPayments)
		.requireAccess("UploadBank")
		.requireFeatureToggleEnabled(m.Core.Features.SveaInternationalPayments)
		.require(() => hasSvea)
		.addSub(getRoute("sveaSwishOverview", companyIdParams), bankLang.Swish)
		.requireAccess("UploadBank")
		.requireFeatureAvailability("BokioBusinessAccount");

	// Failed Payment Bank State
	// prettier-ignore
	menu.addSection("bank", lang.Menu_Bank)
	.require(() => hasFailedPayments && hasSvea)
	.route(getRoute("blockedBankList", companyIdParams))
	.requireAccess("UploadBank")
	.addSub(getRoute("blockedBankList", companyIdParams), lang.MenuItem_Accounts)
	.requireAccess("UploadBank")
	.addSub(getRoute("cardList", companyIdParams), bankLang.BokioCards)
	.requireAccess("UploadBank")

	// prettier-ignore
	menu.addSection("accounting", lang.Menu_AccountingServices)
		.requirePaidPlan()
		.addSub(getRoute("eventsToRecord", companyIdParams),  lang.Menu_ETR_MenuTitle)
		.addBadge({ color: "complete", name: "BETA" })
		.requireAccess("Bookkeep")
		.requireFeatureToggleEnabled(m.Core.Features.EventsToRecord)
		.requirePaidPlan()
		.addSub(getRoute("accountedList", companyIdParams), lang.Menu_Accounted)
		.requireAccess("ViewBookkeeping")
		.addSub(getRoute("closures", companyIdParams), lang.Menu_Closures)
		.requireAccess("Closures")
		.addHiddenSub(`/${companyIdParams.company}/edit-verification`)
		.requireAccess("ViewBookkeeping")
		.addSub(getRoute("assetsManagement", companyIdParams), lang.FixedAssets_Menu)
		.requireAccess("Reports")
		.requireAccess("ViewBookkeeping");

	// prettier-ignore
	menu.addSection("invoices", lang.InvoiceApplication)
		.requirePaidPlan()
		.addSub(getRoute("invoices", companyIdParams), lang.Invoices)
		.requireAccess("Invoices")
		.addSub(getRoute("recurringInvoices", companyIdParams), lang.RecurringInvoices)
		.requireAccess("Invoices")
		.addSub(getRoute("quotes", companyIdParams), lang.Menu_Quotes)
		.requireAccess("Invoices")
		.addSub(getRoute("customers", companyIdParams), lang.Menu_Customers)
		.requireAccess("Invoices")
		.addSub(getRoute("articles", companyIdParams), lang.Menu_Articles)
		.requireAccess("Invoices")
		.addSub(getRoute("rotRutErrands", companyIdParams), lang.Menu_RotRutErrands)
		.requireAccess("Invoices")
		.requireFeatureAvailability("RotRut");

	// prettier-ignore
	menu
		.addSection("sales", lang.Menu_Sales)
		.requirePaidPlan()
		.addSub(getRoute("ecommerce", companyIdParams), lang.ECommerce)
		.requireAccess("InvoiceSettings")
		.addBadge({
			name: lang.Beta,
			color: "attention",
			showPredicate: () => getCurrentCountryIso() === swedishCountryIso2
		})
		.addSub(getRoute("dailyTakings", companyIdParams), lang.Menu_DailyTakings)
		.requireAccess("InvoiceSettings");

	// prettier-ignore
	menu.addSection("suppliers", lang.SuppliersPurchases_label)
		.requirePaidPlan()
		.addSub(getRoute("suppliersInvoicesAll", companyIdParams), lang.Menu_SupplierInvoices_Invoices)
		.requireAccess("SupplierInvoices")
		.addSub(getRoute("suppliers", companyIdParams), lang.Menu_Suppliers)
		.requireAccess("SupplierInvoices");

	// prettier-ignore

	if (company.Country === m.CountryCode.SE) {
		// Salary menu SE
		const salaryMenu = menu
			.addSection("salary", lang.StaffAndSalaries_label)
			.requirePaidPlan()
			.requireFeatureAvailability("Salary")
			.addSub(getRoute("salaryTaxPeriodList", companyIdParams), lang.Menu_SalaryOverview)
			.requireAccess("Salaries")
			.requireFeatureAvailability("Salary")
			.addSub(getRoute("myEmployeeSalary", companyIdParams), lang.Menu_MyPayslips)
			.addSub(getRoute("employeeList", companyIdParams), lang.Menu_Employees)
			.requireAccess("Salaries", "AdminUsers")
			.requireFeatureAvailability("Salary");
		salaryMenu
			.addSub(getRoute("vacationTracking", companyIdParams), lang.Menu_Vacation)
			.requireAccess("Salaries", "AdminUsers")
			.requireFeatureAvailability("Salary")
			.addOnClick(() => {
				visitMenuItem("VacationTracking");
			});
	}

	if (company.Country === m.CountryCode.GB && company.CompanyType == CompanyType.LimitedCompany) {
		// Salary menu UK
		menu
			.addSection("salary", lang.StaffAndSalaries_label)
			.requirePaidPlan()
			// todo: Show the setupflow rather than the payroll summary for users who haven't finished setup.
			// At the moment it's pointed at the summary page for testing purposes.
			.addSub(getRoute("directorsPayrollList", companyIdParams), lang.Menu_Directors_Payroll)
			.requireAccess("Salaries")
			.addSub(getRoute("myEmployeeSalary", companyIdParams), lang.Menu_MyPayslips)
			.addSub(getRoute("employeeList", companyIdParams), lang.Menu_Employees)
			.requireAccess("Salaries", "AdminUsers")
			.addHiddenSub(getRoute("employerDirectorSetup", { company: company.Id }));
	}

	// prettier-ignore
	const expensesMenu = menu.addSection("expenses", lang.ExpenseClaims)
		.requirePaidPlan()
		.addSub(getRoute("expenses", companyIdParams), lang.Menu_Expenses_MyExpenses)
		.requireAccess("UploadExpense", "AdminUsers")
		.addSub(getRoute("expensesPayments", companyIdParams), lang.Menu_Expenses_Payments)
		.requireAccess("Disbursements")
		.addSub(getRoute("expensesOverview", companyIdParams), lang.Menu_Expenses_All)
		.requireAccess("Disbursements")
		.addSub(getRoute("expensesPermissions", companyIdParams), lang.Permissions)
		.requireAccess("AdminUsers")
		.addHiddenSub(`/${companyIdParams.company}/expenses/upload`)
		.addHiddenSub(`/${companyIdParams.company}/expenses/view`);

	if (company.Country !== m.CountryCode.GB) {
		// Employees are already shown under salaries in the UK and is not needed here.
		expensesMenu
			.addSub(getRoute("employeeList", companyIdParams), lang.Menu_Employees)
			// Hides employees for SE if Salary is available so as not to show it in the menu twice
			.requireFeatureNotAvailable("Salary")
			.requireAccess("AdminUsers");
	}

	menu.addDivider();

	if (company.Country === m.CountryCode.SE) {
		// prettier-ignore
		menu.addSection("marketplace", lang.Menu_AdditionalServices)
			.requirePaidPlan()
			.addOnClick(() => {
				visitMenuItem("BusinessServices");
			})
			.addSub(getRoute("accountingServices", companyIdParams), lang.Menu_HireAccountant)
			.requireAccess("CompanySettings")
			.addSub(getRoute("businessServices", companyIdParams), lang.Menu_Offers)
			.requireAccess("CompanySettings")
			.addSub(getRoute("integrations", companyIdParams), settingsLang.Integrations_heading)
			.requireAccess("CompanySettings")
	}
	if (company.Country === m.CountryCode.GB) {
		menu
			.addSection("addons", settingsLang.Integrations_heading)
			.requirePaidPlan()
			.addOnClick(() => {
				visitMenuItem("Integrations");
			})
			.addSub(getRoute("integrations", companyIdParams), settingsLang.Integrations_heading)
			.requireAccess("CompanySettings");
	}
	// prettier-ignore
	menu.addSection("settings", lang.Settings)
		.route(getRoute("settingsOverview", companyIdParams));

	const structure = menu.build();

	const sections = filterMenuSections(
		company.Plan,
		company.BillingPaymentState === m.Entities.BillingSubscriptionPaymentState.BlockedDueToMissingPayment,
		company.CompanySystem === m.Entities.CompanySystem.TestCompany,
		features,
		structure,
		Feature.IsEnabled,
		deviceQuery,
		companyUserPermissions,
		company.PartnerStatus?.PartnerCompanyType,
	);

	return props.children(sections);
};

export interface CompanyMenuProviderProps {
	children: (sections: MenuSection[]) => React.ReactElement;
}

const CompanyMenuProvider = (props: CompanyMenuProviderProps) => {
	const { company, companyUserPermissions } = useCompanyUser();
	if (company) {
		return <CompanyMenuProviderInner company={company} companyUserPermissions={companyUserPermissions} {...props} />;
	} else {
		return props.children([]);
	}
};

export default CompanyMenuProvider;
